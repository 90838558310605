/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Button, Col, Form, Input, Row } from "antd";

import archivos from "./../../assets/img/archivos.svg";

const Documento = (props) => {
  const [form] = Form.useForm();

  const { current, onClose } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  const onDownloadClick = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${current.id}`,
      "_blank"
    );
    onClose();
  };

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={archivos} alt="Logo" />

      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Nombre" name="nombre">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tipo" name="tipo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha" name="fecha">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tamaño" name="tamanio">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button type="primary" className="modal-btn" onClick={onDownloadClick}>
          Descargar
        </Button>
      </div>
    </Form>
  );
};

export default Documento;
