import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";

import http from "./../../services/http";

import Familiar from "./Familiar";

import icon from "./../../assets/img/familiares.svg";

const Familiares = () => {
  const [familiares, setFamiliares] = useState([]);
  const [familiar, setFamiliar] = useState();

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`micrositios/empleados/familiares`);
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setFamiliares(data.familiares);
      }
    }

    getData();
  }, []);

  return (
    <div>
      <div className="card-form">
        <div className="header">
          <img className="icon" src={icon} alt="Logo" />
          <div className="data">
            <div className="title">Grupo Familiar</div>
            <div className="description">Lista de familiares del empleado</div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th className="optional">Vínculo</th>
              <th className="optional">Sexo</th>
            </tr>
          </thead>
          <tbody>
            {familiares.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {familiares.map((item, index) => (
              <tr key={index} onClick={() => setFamiliar(item)}>
                <td>{item.nombre}</td>
                <td className="optional">{item.vinculo}</td>
                <td className="optional">{item.sexo}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={familiar}
          onCancel={() => setFamiliar(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Familiar current={familiar} />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Familiares;
