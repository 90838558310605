/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import icon from "./../../assets/img/licencias.svg";
import { FiPaperclip } from "react-icons/fi";
import Archivos from "./Archivos";
const { TextArea } = Input;

const Licencia = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <div>
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={icon} alt="Logo" />
      <Row gutter={20}>

        <Col xs={12}>
          <Form.Item label="Fecha" name="creacion">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Concepto" name="concepto">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Fecha Inicio" name="fecha">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Vencimiento" name="vencimiento">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Días" name="valor">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Estado" name="estado">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Observaciones" name="observacion">
            <TextArea readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    {current.concepto === 'Licencia por Vacaciones' && (
          <React.Fragment>
            <hr />
            <div className="section-header">
              <div className="section-title">
                Documentación
                <FiPaperclip />
              </div>
              <div className="section-subtitle">
              Documentación asociada con la licencia.
              </div>
            </div>
            <Archivos licenciaVacaciones={current.id} />
          </React.Fragment>
        )}
    </div>
  );
};

export default Licencia;
