import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

import iconPersonales from "./../assets/img/personales.svg";
import iconLaborales from "./../assets/img/laborales.svg";
import iconEstudios from "./../assets/img/estudios.svg";
import iconFamiliares from "./../assets/img/familiares.svg";
import iconLiquidaciones from "./../assets/img/liquidaciones.svg";
import iconAsistencias from "./../assets/img/asistencias.svg";
import iconLicencias from "./../assets/img/licencias.svg";
import iconEmbargos from "./../assets/img/embargos.svg";
import iconCuotas from "./../assets/img/cuotas.svg";

import "./Home.scoped.scss";

const Home = () => {
  useEffect(() => {}, []);

  return (
    <div className="home">
      <Row gutter={[24, 24]} justify="center">
        <Col xs={24} lg={8}>
          <Link to="/personales">
            <div className="section">
              <img className="icon" src={iconPersonales} alt="Logo" />
              <div className="data">
                <div className="title">Datos Personales</div>
                <div className="description">
                  Datos básicos del empleado y documentación adjunta
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/laborales">
            <div className="section">
              <img className="icon" src={iconLaborales} alt="Logo" />
              <div className="data">
                <div className="title">Datos Laborales</div>
                <div className="description">
                  Información del trabajo actual y experiencias anteriores
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/estudios">
            <div className="section">
              <img className="icon" src={iconEstudios} alt="Logo" />
              <div className="data">
                <div className="title">Estudios y Formación</div>
                <div className="description">
                  Lista de estudios realizados y sus asignaciones
                  correspondientes
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/familiares">
            <div className="section">
              <img className="icon" src={iconFamiliares} alt="Logo" />
              <div className="data">
                <div className="title">Grupo Familiar</div>
                <div className="description">
                  Composición del grupo familiar del empleado
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/liquidaciones">
            <div className="section">
              <img className="icon" src={iconLiquidaciones} alt="Logo" />
              <div className="data">
                <div className="title">Liquidaciones</div>
                <div className="description">
                  Bonos de sueldo recibidos por año
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/asistencias">
            <div className="section">
              <img className="icon" src={iconAsistencias} alt="Logo" />
              <div className="data">
                <div className="title">Asistencias</div>
                <div className="description">
                  Registro de asistencia mensual
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/licencias">
            <div className="section">
              <img className="icon" src={iconLicencias} alt="Logo" />
              <div className="data">
                <div className="title">Vacaciones</div>
                <div className="description">
                  Historial de vacaciones tomadas
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/embargos">
            <div className="section">
              <img className="icon" src={iconEmbargos} alt="Logo" />
              <div className="data">
                <div className="title">Embargos</div>
                <div className="description">
                  Lista de embargos que recibidos a lo largo del tiempo
                </div>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={24} lg={8}>
          <Link to="/cuotas">
            <div className="section">
              <img className="icon" src={iconCuotas} alt="Logo" />
              <div className="data">
                <div className="title">Cuotas Alimentarias</div>
                <div className="description">
                  Lista de cuotas alimentarias incluidas en el bono de sueldo
                </div>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
