/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, Modal, Row } from "antd";

import http from "./../../services/http";

import Antecedente from "./Antecedente";
import Documento from "./Documento";

import iconAntecedentes from "./../../assets/img/antecedentes.svg";
import iconArchivos from "./../../assets/img/archivos.svg";
import iconLaborales from "./../../assets/img/laborales.svg";

const Laborales = () => {
  const [form] = Form.useForm();

  const [antecedentes, setAntecedentes] = useState([]);
  const [antecedente, setAntecedente] = useState();

  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState();

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`micrositios/empleados/laborales`);
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        form.setFieldsValue(data.form);
        setAntecedentes(data.antecedentes);
        setDocumentos(data.documentos);
      }
    }

    getData();
  }, []);

  return (
    <div>
      <Form form={form} className="card-form" layout="vertical">
        <div className="header">
          <img className="icon" src={iconLaborales} alt="Logo" />
          <div className="data">
            <div className="title">Datos Laborales</div>
            <div className="description">
              Información del trabajo actual y experiencias anteriores
            </div>
          </div>
        </div>

        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <Form.Item label="Función" name="funcion">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Secretaría" name="secretaria">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Dirección" name="direccion">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Subdirección" name="subDireccion">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Lugar de Trabajo" name="lugarTrabajo">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Convenio" name="convenio">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Categoría" name="clase">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Retiene Categoría" name="esClaseRetenida">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Categoría Retenida" name="claseRetenida">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Agrupamiento" name="agrupamiento">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label="Retiene Agrupamiento"
              name="esAgrupamientoRetenido"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={16}>
            <Form.Item label="Agrupamiento" name="agrupamientoRetenido">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Fecha Ingreso" name="fechaIngreso">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Antigüedad" name="antiguedad">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Antigüedad Reconocida"
              name="antiguedadReconocida"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Antigüedad Total" name="antiguedadTotal">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Subroga Cargo" name="subrogaCargo">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={16}>
            <Form.Item label="Cargo Interino" name="cargoInterino">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Cargo Interino Desde" name="cargoInterinoDesde">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Cargo Interino Hasta" name="cargoInterinoHasta">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Régimen de Trabajo" name="regimenTrabajo">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Horario de Trabajo" name="horarioDesde">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Hasta" name="horarioHasta">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Reducción Horaria" name="reduccionHoraria">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Porcentaje Reducción" name="reduccionPorcentaje">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label="Marca en Reloj Biométrico"
              name="tieneRelojBiometrico"
            >
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Locación del Reloj Biométrico"
              name="relojBiometrico"
            >
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Correo Electrónico Corporativo"
              name="emailCorporativo"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Teléfono Corporativo" name="telefonoCorporativo">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Condición Sindical" name="condicionSindical">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={16}>
            <Form.Item label="Sindicato" name="sindicato">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Delegado Sindical Desde" name="delegadoDesde">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Delegado Sindical Hasta" name="delegadoHasta">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Cargo Sindical del Delegado" name="cargoSindical">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Cargo Sindical Desde" name="sindicalDesde">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Cargo Sindical Hasta" name="sindicalHasta">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Licencia" name="regimenLicencia">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Horas por Mes" name="horasMes">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Horas por Día" name="horasDia">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Insalubre" name="insalubre">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Desde" name="insalubreDesde">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Hasta" name="insalubreHasta">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Tarea" name="tarea">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Tipo" name="tareaTipo">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Riesgosa" name="tareaRiesgosa">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Porcentaje" name="tareaPorcentaje">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Desde" name="tareaDesde">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Hasta" name="tareaHasta">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Forma de Pago" name="formaPago">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Banco" name="banco">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Cuenta" name="cuenta">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="CBU" name="cbu">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={16}>
            <Form.Item label="Obra Social" name="obraSocial">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="N° Afiliado" name="obraSocialNumero">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="A.R.T" name="art">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Estado" name="estado">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={16}>
            <Form.Item label="Motivo de Baja" name="motivoBaja">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Fecha de Baja" name="fechaBaja">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Responsabilidad Jerárquica"
              name="responsabilidadJerarquica"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="card-form">
        <div className="header">
          <img className="icon" src={iconAntecedentes} alt="Logo" />
          <div className="data">
            <div className="title">Antecedentes Laborales</div>
            <div className="description">
              Historial de experiencias laborales del empleado.
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Empresa</th>
              <th className="optional">Puesto</th>
              <th className="optional">Desde</th>
              <th className="optional">Hasta</th>
            </tr>
          </thead>
          <tbody>
            {antecedentes.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {antecedentes.map((item, index) => (
              <tr key={index} onClick={() => setAntecedente(item)}>
                <td>{item.empresa}</td>
                <td className="optional">{item.puesto}</td>
                <td className="optional">{item.desde}</td>
                <td className="optional">{item.hasta}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={antecedente}
          onCancel={() => setAntecedente(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Antecedente current={antecedente} />
        </Modal>
      </div>

      <div className="card-form">
        <div className="header">
          <img className="icon" src={iconArchivos} alt="Logo" />
          <div className="data">
            <div className="title">Documentos</div>
            <div className="description">
              Documentación asociada con la información personal del empleado
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th className="optional">Tipo</th>
              <th className="optional">Fecha</th>
              <th className="optional">Tamaño</th>
            </tr>
          </thead>
          <tbody>
            {documentos.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {documentos.map((item, index) => (
              <tr key={index} onClick={() => setDocumento(item)}>
                <td>{item.nombre}</td>
                <td className="optional">{item.tipo}</td>
                <td className="optional">{item.fecha}</td>
                <td className="optional">{item.tamanio}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={documento}
          onCancel={() => setDocumento(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Documento
            current={documento}
            onClose={() => setDocumento(undefined)}
          />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Laborales;
