import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import http from "./../../services/http";

import Liquidacion from "./Liquidacion";

import icon from "./../../assets/img/liquidaciones.svg";

const Liquidaciones = () => {
  const [anio, setAnio] = useState(new Date().getFullYear());

  const [liquidaciones, setLiquidaciones] = useState([]);
  const [liquidacion, setLiquidacion] = useState();

  useEffect(() => {
    async function getData() {
      const rData = await http.get(
        `micrositios/empleados/liquidaciones?anio=${anio}`
      );
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setLiquidaciones(data.liquidaciones);
      }
    }

    getData();
  }, [anio]);

  return (
    <div>
      <div className="card-form">
        <div className="header">
          <img className="icon" src={icon} alt="Logo" />
          <div className="data">
            <div className="title">Liquidaciones</div>
            <div className="description">Bonos de sueldo recibidos por año</div>
          </div>
        </div>
        <div className="year">
          <div className="back" onClick={() => setAnio(anio - 1)}>
            <FiChevronLeft />
          </div>
          <div>{anio}</div>
          <div className="next" onClick={() => setAnio(anio + 1)}>
            <FiChevronRight />
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Mes</th>
              <th>Tipo</th>
              <th className="optional">Fecha</th>
              <th className="optional">Neto</th>
            </tr>
          </thead>
          <tbody>
            {liquidaciones.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {liquidaciones.map((item, index) => (
              <tr key={index} onClick={() => setLiquidacion(item)}>
                <td>{item.mes}</td>
                <td>{item.tipo}</td>
                <td className="optional">{item.fecha}</td>
                <td className="optional">{item.netoTexto}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={liquidacion}
          onCancel={() => setLiquidacion(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Liquidacion
            current={liquidacion}
            onClose={() => setLiquidacion(undefined)}
          />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Liquidaciones;
