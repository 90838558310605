import React, { useState } from "react";
import { Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Col, Row } from "antd";

import { ContextProvider } from "./../../components/Context";
import { bounceTransition, mapStyles } from "./../../services/router";

import Forgot from "./Forgot";
import Login from "./Login";
import Reset from "./Reset";
import Update from "./Update";

import imgLogo from "./../../assets/img/icon.png";

import "./Authentication.scss";

const Authentication = () => {
  const [state, setState] = useState({ img: "login", loading: false });

  return (
    <ContextProvider value={[state, setState]}>
      <div className="page-authentication">
        <img src={imgLogo} alt="logo" className="logo" />

        <Row>
          <Col xs={0} lg={12} xl={14}>
            <div className="image">
              <img src={state.img} alt="login" />
            </div>
          </Col>
          <Col xs={24} lg={12} xl={10}>
            <AnimatedSwitch
              atEnter={bounceTransition.atEnter}
              atLeave={bounceTransition.atLeave}
              atActive={bounceTransition.atActive}
              mapStyles={mapStyles}
              className="route-wrapper"
            >
              <Route path="/auth/update" component={Update} />
              <Route path="/auth/forgot" component={Forgot} />
              <Route path="/auth/reset" component={Reset} />
              <Route path="/auth" component={Login} exact />
            </AnimatedSwitch>
          </Col>
        </Row>
      </div>
    </ContextProvider>
  );
};

export default Authentication;
