/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, Modal, Row } from "antd";

import http from "./../../services/http";

import Documento from "./Documento";

import iconArchivos from "./../../assets/img/archivos.svg";
import iconPersonales from "./../../assets/img/personales.svg";

const Personales = () => {
  const [form] = Form.useForm();

  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState();

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`micrositios/empleados/personales`);
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        form.setFieldsValue(data.form);
        setDocumentos(data.documentos);
      }
    }

    getData();
  }, []);

  return (
    <div>
      <Form form={form} className="card-form" layout="vertical">
        <div className="header">
          <img className="icon" src={iconPersonales} alt="Logo" />
          <div className="data">
            <div className="title">Datos Personales</div>
            <div className="description">
              Datos básicos del empleado y documentación adjunta
            </div>
          </div>
        </div>

        <Row gutter={20}>
          <Col xs={24} lg={16}>
            <Form.Item label="Nombre y Apellido" name="nombre">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Legajo" name="legajo">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Tipo de Documento" name="documentoTipo">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Documento" name="documento">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="CUIL" name="cuil">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Fecha de Nacimiento" name="fechaNacimiento">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={16}>
            <Form.Item label="Calle" name="calle">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Provincia" name="provincia">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Barrio" name="barrio">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Manzana" name="manzana">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Casa" name="casa">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Departamento" name="departamento">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Distrito" name="distrito">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Código Postal" name="codigoPostal">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Declaración Jurada del Domicilio"
              name="domicilioDdjj"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Fecha de Entrega" name="domicilioDdjjFecha">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Teléfono Particular" name="telefono">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Teléfono Celular #1" name="celular1">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Teléfono Celular #2" name="celular2">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Correo Electrónico Personal" name="email">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Lugar de nacimiento" name="lugarNacimiento">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Nacionalidad" name="nacionalidad">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Estado Civil" name="estadoCivil">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Sexo" name="sexo">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Licencia de Conducir" name="licencia">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Categoría" name="licenciaConducir">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Vencimiento" name="licenciaVencimiento">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item label="Cert. Buena Conducta" name="buenaConducta">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="Grupo Sanguíneo" name="grupoSanguineo">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label="DDJJ" name="declaracionJurada">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="card-form">
        <div className="header">
          <img className="icon" src={iconArchivos} alt="Logo" />
          <div className="data">
            <div className="title">Documentos</div>
            <div className="description">
              Documentación asociada con la información personal del empleado
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th className="optional">Tipo</th>
              <th className="optional">Fecha</th>
              <th className="optional">Tamaño</th>
            </tr>
          </thead>
          <tbody>
            {documentos.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {documentos.map((item, index) => (
              <tr key={index} onClick={() => setDocumento(item)}>
                <td>{item.nombre}</td>
                <td className="optional">{item.tipo}</td>
                <td className="optional">{item.fecha}</td>
                <td className="optional">{item.tamanio}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={documento}
          onCancel={() => setDocumento(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Documento
            current={documento}
            onClose={() => setDocumento(undefined)}
          />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Personales;
