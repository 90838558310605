/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row } from "antd";

import icon from "./../../assets/img/liquidaciones.svg";
import { useEffect } from "react";

const Liquidacion = (props) => {
  const [form] = Form.useForm();

  const { current, onClose } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  const onDownloadClick = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/micrositios/empleados/liquidaciones/${current.id}`,
      "_blank"
    );
    onClose();
  };

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={icon} alt="Logo" />
      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Tipo" name="tipo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Año" name="anio">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Mes" name="mes">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha" name="fecha">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Neto" name="netoTexto">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>

      <div className="modal-actions">
        <Button type="primary" className="modal-btn" onClick={onDownloadClick}>
          Descargar
        </Button>
      </div>
    </Form>
  );
};

export default Liquidacion;
