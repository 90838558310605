/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import icon from "./../../assets/img/cuotas.svg";

const Liquidacion = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={icon} alt="Logo" />
      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Concepto" name="concepto">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha Inicio Oficio" name="fechaInicio">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha Fin Oficio" name="fechaBaja">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tipo" name="tipoCuotaAlimentaria">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Hijos vinculados" name="hijos">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="CBU" name="cbu">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Autos" name="autos">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Beneficiario" name="beneficiario">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="CUIL" name="cuil">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Liquidacion;
