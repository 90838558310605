/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input } from "antd";

import { Context } from "../../components/Context";
import http from "./../../services/http";
import { showSuccess } from "./../../services/notificacion";
import { required } from "./../../services/validation";

import imgForgot from "./../../assets/img/forgot.svg";

const Forgot = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);

  const onSubmit = async (values) => {
    setState({ ...state, loading: true });

    const result = await http.post("micrositios/empleados/reset", values);
    setState({ ...state, loading: false });

    if (result && result.data.code === 0) {
      showSuccess(
        "Te enviamos un email con tu nueva contraseña. Revisa tu casilla de spam en caso de que no lo encuentres."
      );
      history.push("/auth");
    }
  };

  const onBack = () => {
    history.push("/auth");
  };

  useEffect(() => {
    setState({ ...state, img: imgForgot });
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item>
        <h2>¿Olvidaste tu contraseña?</h2>
        <p>
          Ingresá tu legajo y te enviaremos las instrucciones para reestablecer
          tu contraseña por correo electrónico.
        </p>
      </Form.Item>

      <Form.Item label="Legajo" name="legajo" rules={[required]}>
        <Input autoFocus />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={state.loading}>
          {state.loading ? "Enviando" : "Enviar"}
        </Button>
      </Form.Item>

      <Form.Item>
        <hr />
      </Form.Item>

      <Form.Item>
        <Button type="secondary" block onClick={onBack}>
          Volver
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Forgot;
