import { Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

notification.config({
  placement: "bottomRight",
  duration: 5,
});

export const showInfo = (message) => {
  notification.info({ message: "Información", description: message });
};

export const showWarning = (message) => {
  notification.warning({ message: "Advertencia", description: message });
};

export const showSuccess = (message) => {
  notification.success({ message: "Éxito", description: message });
};

export const showError = (message) => {
  notification.error({ message: "Error", description: message });
};

export const showConfirm = (title, message, onSuccess) => {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    centered: true,
    content: message,
    okText: "Confirmar",
    cancelText: "Cancelar",
    onOk() {
      onSuccess();
    },
  });
};
