export const required = { required: true, message: 'Este campo es obligatorio' };

export const compare =  (input, message) => {
  return ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue(input) === value) {
        return Promise.resolve();
      }

      return Promise.reject(message);
    }
  });
}

export const regex =  (regex, message) => {
  return () => ({
    validator(rule, value) {
      var re = new RegExp(regex);
      if (!value || re.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(message);
    }
  });
}