import { HashRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import { bounceTransition, mapStyles } from "./services/router";

import Authentication from "./views/Authentication/Authentication";
import Register from "./views/Register/Register";
import Master from "./views/Master";

import "./styles/Global.scss";

import "./styles/Breadcrumb.scss";
import "./styles/Buttons.scss";
import "./styles/Forms.scss";
import "./styles/Inputs.scss";
import "./styles/Modals.scss";
import "./styles/Notifications.scss";
import "./styles/Selects.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          <Route path="/register" component={Register} />
          <Route path="/auth" component={Authentication} />
          <Route path="/" component={Master} />
        </AnimatedSwitch>
      </Router>
    </div>
  );
}

export default App;
