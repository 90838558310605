import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import http from "./../../services/http";

import Cuota from "./Cuota";

import icon from "./../../assets/img/cuotas.svg";

const Cuotas = () => {
  const [anio, setAnio] = useState(new Date().getFullYear());

  const [cuotas, setCuotas] = useState([]);
  const [cuota, setCuota] = useState();

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`micrositios/empleados/cuotas?anio=${anio}`);
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setCuotas(data.cuotas);
      }
    }

    getData();
  }, [anio]);

  return (
    <div>
      <div className="card-form">
        <div className="header">
          <img className="icon" src={icon} alt="Logo" />
          <div className="data">
            <div className="title">Cuotas Alimentarias</div>
            <div className="description">
              Lista de cuotas alimentarias incluidas en el bono de sueldo
            </div>
          </div>
        </div>
        <div className="year">
          <div className="back" onClick={() => setAnio(anio - 1)}>
            <FiChevronLeft />
          </div>
          <div>{anio}</div>
          <div className="next" onClick={() => setAnio(anio + 1)}>
            <FiChevronRight />
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Concepto</th>
              <th className="optional">Tipo</th>
            </tr>
          </thead>
          <tbody>
            {cuotas.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {cuotas.map((item, index) => (
              <tr key={index} onClick={() => setCuota(item)}>
                <td>{item.concepto}</td>
                <td className="optional">{item.tipoCuotaAlimentaria}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={cuota}
          onCancel={() => setCuota(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Cuota current={cuota} />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Cuotas;
