import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";

import http from "./../../services/http";
import { showSuccess } from "./../../services/notificacion";
import { required } from "./../../services/validation";

const Credentials = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onBackClick = () => {
    history.push("/register");
  };

  const onSubmit = async (values) => {
    const result = await http.post("micrositios/empleados/register", values);

    if (result && result.data.code === 0) {
      showSuccess(
        "Te enviamos un email con tus credenciales. Revisa tu casilla de spam en caso de que no lo encuentres."
      );
      history.push("/auth");
    }
  };

  return (
    <Form
      form={form}
      className="credentials"
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item>
        <h2>Ingresá tu correo electrónico</h2>
        <p>
          Ahora, ingresá un correo electrónico para que podamos usar como
          contacto.
        </p>
      </Form.Item>

      <Form.Item label="Legajo" name="legajo" rules={[required]}>
        <Input autoFocus />
      </Form.Item>

      <Form.Item label="Correo Electrónico" name="email" rules={[required]}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Continuar
        </Button>
      </Form.Item>

      <Form.Item>
        <hr />
      </Form.Item>

      <Form.Item>
        <Button type="secondary" block onClick={onBackClick}>
          Volver
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Credentials;
