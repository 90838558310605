/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Button } from "antd";
import icon from "./../../assets/img/licencias.svg";

import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";

import { parseYear, parseDate } from "../../services/forms";
import http from "../../services/http";
import { showSuccess } from "../../services/notificacion";
import { required } from "../../services/validation";

import { ContextProvider } from "../../components/Context";

import "./Licencia.scss";

const { TextArea } = Input;
const { Option } = Select;

const LicenciasForm = ({ onFinish }) => {
  const [conceptos, setConceptos] = useState([]);
  const [codigos, setCodigos] = useState([]);

  const [state, setState] = useState({
    loading: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    async function getForm() {
      const rData = await http.get("micrositios/empleados/licencias/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setConceptos(data.conceptos);
        setCodigos(data.codigos);
      }
    }

    getForm();
  }, []);

  const onEmpleadoChange = async () => {
    let values = form.getFieldsValue();

    if (values.anio) {
      values.anio = parseYear(values.anio);

      const result = await http.post(
        "micrositios/empleados/licencias/dias",
        values
      );
      if (result && result.data.code === 0) {
        const data = result.data.data;
        form.setFieldsValue({ saldoAnual: data.disponibles });
        form.setFieldsValue({ acumuladoAnual: data.acumulado });
      }
    }
  };

  const onInicioChange = () => {
    let values = form.getFieldsValue();

    if (values.cantidadDiasdias && values.fechaInicio) {
      values.fechaFin = addBusinessDays(
        values.fechaInicio.clone(),
        values.cantidadDias >= 1
          ? values.cantidadDias - 1
          : values.cantidadDias - 0.5
      );
    } else {
      values.fechaFin = null;
    }

    form.setFieldsValue(values);
  };

  function addBusinessDays(originalDate, numDaysToAdd) {
    const newDate = originalDate.clone();
    newDate.add(numDaysToAdd, "days");
    return newDate;
  }

  const onDiasChange = () => {
    let values = form.getFieldsValue();
    if (parseInt(values.cantidadDias) < 0) {
      values.cantidadDias = 0;
    }
    if (parseInt(values.cantidadDias) > parseInt(values.saldoAnual)) {
      values.cantidadDias = values.saldoAnual;
    }

    if (values.cantidadDias && values.fechaInicio) {
      values.fechaFin = addBusinessDays(
        values.fechaInicio.clone(),
        values.cantidadDias >= 1
          ? values.cantidadDias - 1
          : values.cantidadDias - 0.5
      );
    } else {
      values.fechaFin = null;
    }

    form.setFieldsValue(values);
  };

  function disabledDate(current) {
    return (
      current && (current < moment().add(-2, "years") || current > moment())
    );
  }

  const onCodigoChange = (value) => {
    const item = codigos.find((x) => x.id === parseInt(value));
    const values = {
      concepto: item ? item.conceptoId : "",
    };
    form.setFieldsValue(values);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.anio = parseYear(values.anio);
    values.inicio = parseDate(values.inicio);
    values.fin = parseDate(values.fin);

    const result = await http.post("micrositios/empleados/licencias", values);

    if (result && result.data.code === 0) {
      showSuccess("La licencia fue guardada exitosamente.");
      onFinish();
    } else {
      setState({ ...state, loading: false });
    }
  };

  return (
    <ContextProvider value={[state, setState]}>
      <div className="">
        <div className="header">
          <img className="icon" src={icon} alt="Logo" />
          <div className="data">
            <div className="title">Vacaciones</div>
            <div className="description">
              Cargar un nuevo período de vacaciones
            </div>
          </div>
        </div>
        <div>
          <Form form={form} layout="vertical" onFinish={onSave}>
            <Row gutter={20}>
              <Col span="6">
                <Form.Item label="Año" name="anio" rules={[required]}>
                  <DatePicker
                    picker="year"
                    placeholder=""
                    disabledDate={disabledDate}
                    onChange={onEmpleadoChange}
                  />
                </Form.Item>
              </Col>
              <Col span="9">
                <Form.Item label="Código" name="codigo" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={onCodigoChange}
                  >
                    {codigos.map((x) => (
                      <Option
                        key={x.id}
                        value={x.id.toString()}
                        disabled={x.disabled}
                      >
                        {x.codigo} - {x.detalle}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="9">
                <Form.Item label="Concepto" name="concepto" rules={[required]}>
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    disabled={true}
                  >
                    {conceptos.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Fecha Inicio"
                  name="fechaInicio"
                  rules={[required]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    onChange={(val) => onInicioChange(val)}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Cantidad de días"
                  name="cantidadDias"
                  rules={[required]}
                >
                  <Input type="number" onChange={(val) => onDiasChange(val)} />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item label="Fecha Fin" name="fechaFin">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Acumulado Anual" name="acumuladoAnual">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Saldo Anual" name="saldoAnual">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Observaciones" name="observacion">
                  <TextArea maxLength={500} showCount />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </ContextProvider>
  );
};

export default LicenciasForm;
