/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import iconEstudios from "./../../assets/img/estudios.svg";

const Estudios = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={iconEstudios} alt="Logo" />
      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Título" name="titulo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Nivel de Estudio" name="nivelEstudio">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Establecimiento" name="establecimiento">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Periodo Desde" name="desde">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Periodo Hasta" name="hasta">
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Estado" name="estado">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Título Inherente a Función" name="tituloInherente">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tipo Capacitación" name="tipoCapacitacion">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Acto Administrativo" name="actoAdministrativo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Expediente o Nota de Asignación Títulos"
            name="expediente"
          >
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Comentarios" name="comentarios">
            <Input.TextArea rows={4} readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Estudios;
