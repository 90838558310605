import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import { SpinnerCircular } from "spinners-react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import http from "./../../services/http";

import Licencia from "./Licencia";

import icon from "./../../assets/img/licencias.svg";
import LicenciasForm from "./LicenciasForm";

const Liquidaciones = () => {
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [disponibles, setDisponibles] = useState();

  const [licencias, setLicencias] = useState([]);
  const [licencia, setLicencia] = useState();
  const [licenciaForm, setLicenciaForm] = useState();
  const [state, setState] = useState({
    reload: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const rData = await http.get(
        `micrositios/empleados/licencias?anio=${anio}`
      );
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setLicencias(data.licencias);
      }

      setLoading(false);
    }

    getData();
  }, [anio, state.reload]);

  useEffect(() => {
    async function getSaldo() {
      setDisponibles("");

      var values = { anio };

      const result = await http.post(
        "micrositios/empleados/licencias/dias",
        values
      );
      if (result && result.data.code === 0) {
        const data = result.data.data;
        setDisponibles(`${data.disponibles} días disponibles`);
      }
    }

    getSaldo();
  }, [anio]);

  const onFinish = async (values) => {
    setLicenciaForm(false);
    setState({ ...state, reload: !state.reload });
  };

  return (
    <div>
      <div className="card-form">
        <div className="header">
          <img className="icon" src={icon} alt="Logo" />
          <div className="data">
            <div className="title">Vacaciones</div>
            <div className="description">Historial de vacaciones tomadas</div>
          </div>
          <div className="right">
            <Button type="secondary" onClick={() => setLicenciaForm(true)}>
              Nueva
            </Button>
          </div>
        </div>
        <div className="year">
          <div className="back" onClick={() => setAnio(anio - 1)}>
            <FiChevronLeft />
          </div>
          <div className="data">
            <div className="title">{anio}</div>
            <div className="description">{disponibles}</div>
          </div>
          <div className="next" onClick={() => setAnio(anio + 1)}>
            <FiChevronRight />
          </div>
        </div>
        {loading && (
          <div className="loading">
            <SpinnerCircular
              size="60px"
              color="#04bebe"
              secondaryColor="#eeedfd"
            />
            <div className="description">Cargando...</div>
          </div>
        )}

        {!loading && (
          <table className="table">
            <thead>
              <tr>
                <th>Fecha </th>
                <th>Concepto</th>
                <th>Días</th>
                <th className="optional">Fecha Inicio</th>
                <th className="optional">Vencimiento</th>
                <th className="optional">Estado</th>
              </tr>
            </thead>
            <tbody>
              {licencias.length === 0 && (
                <tr>
                  <td colSpan={6} className="empty">
                    No se encontro ningún resultado
                  </td>
                </tr>
              )}
              {licencias.map((item, index) => (
                <tr key={index} onClick={() => setLicencia(item)}>
                  <td>{item.creacion}</td>

                  <td>{item.concepto}</td>
                  <td>{item.valor}</td>
                  <td className="optional">{item.fecha}</td>
                  <td className="optional">{item.vencimiento}</td>
                  <td className="optional">{item.estado}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Modal
          visible={licencia}
          onCancel={() => setLicencia(undefined)}
          footer={null}
          destroyOnClose={true}
          width={650}
        >
          <Licencia current={licencia} />
        </Modal>
        <Modal
          visible={licenciaForm}
          footer={null}
          destroyOnClose={true}
          centered={true}
          width={"70%"}
          onCancel={() => setLicenciaForm(false)}
        >
          <LicenciasForm onFinish={onFinish} />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Liquidaciones;
