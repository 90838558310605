/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import http from "./../services/http";
import { bounceTransition, mapStyles } from "./../services/router";

import Header from "../components/Header";
import Home from "./Home";
import Laborales from "./Laborales/Laborales";
import NavBar from "../components/NavBar";
import Personales from "./Personales/Personales";
import Liquidaciones from "./Liquidaciones/Liquidaciones";
import Asistencias from "./Asistencias/Asistencias";
import Cuotas from "./Cuotas/Cuotas";
import Embargos from "./Embargos/Embargos";
import Estudios from "./Estudios/Estudios";
import Familiares from "./Familiares/Familiares";
import Licencias from "./Licencias/Licencias";

import "./Master.scss";

const Master = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    async function validate() {
      const result = await http.post("micrositios/empleados/validate", {});
      if (result && result.data.code !== 0) {
        localStorage.removeItem("token");
        history.push("/auth");
      } else {
        setVisible(true);
      }
    }

    validate();
  }, []);

  return (
    visible && (
      <div className="master">
        <NavBar />
        <Header />
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          <Route path="/cuotas" component={Cuotas} />
          <Route path="/embargos" component={Embargos} />
          <Route path="/estudios" component={Estudios} />
          <Route path="/familiares" component={Familiares} />
          <Route path="/laborales" component={Laborales} />
          <Route path="/asistencias" component={Asistencias} />
          <Route path="/licencias" component={Licencias} />
          <Route path="/liquidaciones" component={Liquidaciones} />
          <Route path="/personales" component={Personales} />
          <Route path="/" component={Home} exact />
        </AnimatedSwitch>
      </div>
    )
  );
};

export default Master;
