import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "antd";
import { FiMail } from "react-icons/fi";

import "./Start.scoped.scss";

const Start = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onStartClick = () => {
    history.push("/register/credentials");
  };

  const onLoginClick = () => {
    history.push("/auth");
  };

  return (
    <Form form={form} className="start" layout="vertical">
      <Form.Item>
        <h2>¡Registrate!</h2>
        <p>Crearte una cuenta solo te lleva unos minutos.</p>
      </Form.Item>

      <div className="email">
        <div className="icon">
          <FiMail />
        </div>
        <p>
          Solo necesitas tener una cuenta de <b>correo electrónico</b> válida.
        </p>
      </div>

      <Form.Item>
        <Button type="primary" block onClick={onStartClick}>
          Comenzar
        </Button>
      </Form.Item>

      <Form.Item>
        <hr />
      </Form.Item>

      <Form.Item>
        <Button type="secondary" block onClick={onLoginClick}>
          ¿Ya tenes cuenta? Ingresá
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Start;
