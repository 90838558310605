/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input } from "antd";

import { Context } from "../../components/Context";
import http from "../../services/http";
import { required } from "./../../services/validation";

import imgForgot from "./../../assets/img/forgot.svg";

const Update = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);

  const onSubmit = async (values) => {
    setState({ ...state, loading: true });

    const result = await http.post("micrositios/empleados/update", values);
    setState({ ...state, loading: false });

    if (result && result.data.code === 0) {
      history.push("/");
    }
  };

  useEffect(() => {
    setState({ ...state, img: imgForgot });
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item>
        <h2>Actualizá tu contraseña</h2>
        <p>
          Ingresá una nueva contraseña para asegurar tu cuenta. La clave debe
          tener entre 8 y 20 caracteres, al menos un número, una letra mayúscula
          y una letra minúscula
        </p>
      </Form.Item>

      <Form.Item label="Nueva contraseña" name="password" rules={[required]}>
        <Input.Password autoComplete="off" />
      </Form.Item>

      <Form.Item label="Confirmar contraseña" name="confirm" rules={[required]}>
        <Input.Password autoComplete="off" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={state.loading}>
          {state.loading ? "Actualizando" : "Actualizar"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Update;
