/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";

import { Context } from "./../../components/Context";
import http from "./../../services/http";
import { required } from "./../../services/validation";

import imgLogin from "./../../assets/img/login.svg";

import "./Login.scoped.scss";

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [state, setState] = useContext(Context);

  const onSubmit = async (values) => {
    setState({ ...state, loading: true });

    const result = await http.post("micrositios/empleados/login", values);
    setState({ ...state, loading: false });

    if (result && result.data.code === 0) {
      const data = result.data.data;

      localStorage.setItem("token", data.token);

      if (data.reset) {
        history.push("/auth/update");
      } else {
        history.push("/");
      }
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('d')) {
      form.setFieldsValue({ legajo: '2196', password: '123456' });
      form.submit();
    }
  }, []);

  const onRegisterClick = () => {
    history.push("/register");
  };

  useEffect(() => {
    setState({ ...state, img: imgLogin });
  }, []);

  return (
    <Form form={form} className="login" layout="vertical" onFinish={onSubmit}>
      <Form.Item>
        <h2>¡Hola! Bienvenido a tú Municipalidad</h2>
        <p>Ingresa tu legajo y contraseña para continuar</p>
      </Form.Item>

      <Form.Item label="Legajo" name="legajo" rules={[required]}>
        <Input autoFocus />
      </Form.Item>

      <Form.Item label="Contraseña" name="password" rules={[required]}>
        <Input.Password autoComplete="on" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={state.loading}>
          {state.loading ? "Ingresando" : "Ingresar"}
        </Button>
        <Link to="/auth/forgot" className="forgot">
          Olvidé mi contraseña
        </Link>
      </Form.Item>

      <Form.Item>
        <hr />
      </Form.Item>

      <Form.Item>
        <Button type="secondary" block onClick={onRegisterClick}>
          Crea tu cuenta
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
