/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import icon from "./../../assets/img/familiares.svg";

const Familiar = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={icon} alt="Logo" />
      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Nombre y Apellido" name="nombre">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tipo Documento" name="documentoTipo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Documento" name="documento">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="CUIL" name="cuil">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha de Nacimiento" name="fechaNacimiento">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Estado Civil" name="estadoCivil">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Sexo" name="sexo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Vínculo" name="vinculo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Asignación" name="asignacion">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Incapacitado" name="incapacitado">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha de Alta" name="fechaAlta">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha de Baja" name="fechaBaja">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Motivo de Baja" name="motivoBaja">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="D.J.I.G" name="djig">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha de Baja I.G" name="fechaBajaIG">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Seguro Mutual" name="seguroMutual">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tipo de Aporte Obra Social" name="aporteObraSocial">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tipo Voluntario" name="tipoVoluntario">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Prenatal" name="prenatal">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="FUM" name="prenatalFUM">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="FPP" name="prenatalFPP">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Escolaridad" name="escolaridad">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Curso" name="escolaridadCurso">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Certificado Escolar" name="escolaridadCertificado">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Fecha de Presentación"
            name="escolaridadPresentacion"
          >
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha de Vencimiento" name="escolaridadVencimiento">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Familiar;
