/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Form, Input, Button } from "antd";

import { Context } from "../../components/Context";
import { required } from "./../../services/validation";

import imgReset from "./../../assets/img/reset.svg";

const Reset = () => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);


  useEffect(() => {
    setState({ ...state, img: imgReset });
  }, []);

  return (
    <Form form={form} layout="vertical">
      <Form.Item>
        <h2>Actualizá tu contraseña</h2>
        <p>Tu nueva contraseña no puede ser igual a la anterior.</p>
      </Form.Item>

      <Form.Item label="Nueva contraseña" name="password" rules={[required]}>
        <Input.Password autoComplete="off" />
      </Form.Item>

      <Form.Item label="Confirmar contraseña" name="confirm" rules={[required]}>
        <Input.Password autoComplete="off" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Reset;
