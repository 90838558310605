/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import iconAsignaciones from "./../../assets/img/asignaciones.svg";

const Asignacion = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={iconAsignaciones} alt="Logo" />
      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Asignación" name="asignacionPorTitulo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Título (%)" name="titulo">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Adicional (%)" name="adicional">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Fecha" name="fecha">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Instrumento" name="instrumento">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Número" name="instrumentoNumero">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha Acto Administrativo" name="fechaActo">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Expediente" name="expediente">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Autoriza" name="autorizante">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Asignacion;
