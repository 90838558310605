/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { FiEdit, FiMoreVertical, FiSearch } from "react-icons/fi";
import Viewer from "react-viewer";
import { FileIcon, defaultStyles } from "react-file-icon";

import http from "../../services/http";

import "./Archivos.scss";

const Archivos = ({ licenciaVacaciones }) => {
  // eslint-disable-next-line no-unused-vars

  const [files, setFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  const images = ["jpg", "jpeg", "bmp", "gif", "png"];

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    setPreviewVisible(true);
  };

  const onDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      "_blank"
    );
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        {images.indexOf(file.extension) > -1 && (
          <Menu.Item key="0" onClick={() => onPreview(file)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => onDownload(file)}>
          <FiEdit />
          Descargar
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    async function getFiles() {
      const rFiles = await http.get(
        `Micrositios/Empleados/Licencias/Documentacion?licenciaVacaciones=${licenciaVacaciones}`
      );
      if (rFiles && rFiles.data.code === 0) {
        const data = rFiles.data.data;
        setFiles(data.files);
      }
    }

    getFiles();
  }, [licenciaVacaciones]);

  return (
    <div className="archivos">
      <table className="table-small">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th style={{ textAlign: "center" }}>Fecha</th>
            <th style={{ textAlign: "center" }}>Tamaño</th>
          </tr>
        </thead>
        <tbody>
          {files.length === 0 && (
            <tr>
              <td className="table-empty" colSpan="6">
                Sin resultados
              </td>
            </tr>
          )}

          {files.map((x) => (
            <tr key={x.id}>
              <td className="table-actions">
                <Dropdown
                  key={x.id}
                  overlay={() => menu(x)}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <div>
                    <FiMoreVertical />
                  </div>
                </Dropdown>
              </td>
              <td className="td-icon">
                <div className="icon">
                  <FileIcon
                    extension={x.extension}
                    {...defaultStyles[x.extension]}
                  />
                </div>
                {x.nombre}
              </td>
              <td style={{ textAlign: "center" }}>{x.fecha}</td>
              <td style={{ textAlign: "center" }}>{x.tamanio}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />
    </div>
  );
};

export default Archivos;
