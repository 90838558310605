import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import http from "./../../services/http";

import Asistencia from "./Asistencia";

import icon from "./../../assets/img/asistencias.svg";

const Asistencias = () => {
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [mes, setMes] = useState(new Date().getMonth() + 1);

  const [asistencias, setAsistencias] = useState([]);
  const [asistencia, setAsistencia] = useState();

  useEffect(() => {
    async function getData() {
      const rData = await http.get(
        `micrositios/empleados/asistencias?anio=${anio}&mes=${mes}`
      );
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setAsistencias(data.asistencias);
      }
    }

    getData();
  }, [anio, mes]);

  const getMesNombre = () => {
    switch (mes) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
      default:
        return "";
    }
  };

  const onMesAnterior = () => {
    if (mes === 1) {
      setMes(12);
      setAnio(anio - 1);
    } else {
      setMes(mes - 1);
    }
  };

  const onMesSiguiente = () => {
    if (mes === 12) {
      setMes(1);
      setAnio(anio + 1);
    } else {
      setMes(mes + 1);
    }
  };

  return (
    <div>
      <div className="card-form">
        <div className="header">
          <img className="icon" src={icon} alt="Logo" />
          <div className="data">
            <div className="title">Asistencias</div>
            <div className="description">Registro de asistencia mensual</div>
          </div>
        </div>
        <div className="year">
          <div className="back" onClick={() => setAnio(anio - 1)}>
            <FiChevronLeft />
          </div>
          <div>{anio}</div>
          <div className="next" onClick={() => setAnio(anio + 1)}>
            <FiChevronRight />
          </div>
        </div>
        <div className="year">
          <div className="back" onClick={onMesAnterior}>
            <FiChevronLeft />
          </div>
          <div>{getMesNombre()}</div>
          <div className="next" onClick={onMesSiguiente}>
            <FiChevronRight />
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th className="optional">Reloj</th>
            </tr>
          </thead>
          <tbody>
            {asistencias.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {asistencias.map((item, index) => (
              <tr key={index} onClick={() => setAsistencia(item)}>
                <td>{item.fecha}</td>
                <td>{item.hora}</td>
                <td className="optional">{item.reloj}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={asistencia}
          onCancel={() => setAsistencia(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Asistencia current={asistencia} />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Asistencias;
