/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import iconAntecedentes from "./../../assets/img/antecedentes.svg";

const Antecedente = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={iconAntecedentes} alt="Logo" />

      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Empresa" name="empresa">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Dirección" name="direccion">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Puesto" name="puesto">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Desde" name="desde">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Hasta" name="hasta">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Antigüedad" name="antiguedad">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Motivo" name="motivo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Comentarios" name="comentarios">
            <Input.TextArea readOnly></Input.TextArea>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Antecedente;
