import React from "react";
import { Link, useHistory } from "react-router-dom";

import "./NavBar.scoped.scss";

import imgLogo from "./../assets/img/icon.png";
import { showConfirm } from "./../services/notificacion";

const NavBar = () => {
  const history = useHistory();

  const onLogout = () => {
    showConfirm(
      "Cerrar Sesión",
      "¿Esta seguro que desea cerrar su sesión?",
      () => {
        localStorage.removeItem("token");
        history.push("/auth");
      }
    );
  };

  return (
    <div className="navbar">
      <div className="content">
        <div className="brand">
          <Link to="/">
            <img src={imgLogo} alt="logo" />
          </Link>
        </div>

        <div className="logout" onClick={onLogout}>
          Cerrar Sesión
        </div>
      </div>
    </div>
  );
};

export default NavBar;
