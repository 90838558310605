/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import icon from "./../../assets/img/embargos.svg";

const Liquidacion = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={icon} alt="Logo" />
      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Concepto" name="concepto">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Juzgado" name="juzgado">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Acción" name="accion">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Deposito" name="deposito">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Demandante" name="demandante">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Número" name="numero">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Autos" name="autos">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Cantidad de cuotas" name="cuotas">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Monto" name="monto">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Saldo" name="saldo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Acumulado" name="montoAcumulado">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha Inicio" name="fechaInicioTexto">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha Fin" name="fechaInicioTexto">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Estado" name="estadoEmbargo">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="CBU" name="cbu">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Sucural" name="sucursalId">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="¿Descuento Concurso/Quiebra?" name="descuento">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Liquidacion;
