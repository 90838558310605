import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";

import http from "./../../services/http";

import Asignacion from "./Asignacion";
import Estudio from "./Estudio";

import iconAsignaciones from "./../../assets/img/asignaciones.svg";
import iconEstudios from "./../../assets/img/estudios.svg";

const Estudios = () => {
  const [estudios, setEstudios] = useState([]);
  const [estudio, setEstudio] = useState();

  const [asignaciones, setAsignaciones] = useState([]);
  const [asignacion, setAsignacion] = useState();

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`micrositios/empleados/estudios`);
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setEstudios(data.estudios);
        setAsignaciones(data.asignaciones);
      }
    }

    getData();
  }, []);

  return (
    <div>
      <div className="card-form">
        <div className="header">
          <img className="icon" src={iconEstudios} alt="Logo" />
          <div className="data">
            <div className="title">Estudios y Formación</div>
            <div className="description">
              Lista de estudios y capacitaciones del empleado.
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Título</th>
              <th className="optional">Desde</th>
              <th className="optional">Hasta</th>
              <th className="optional">Estado</th>
              <th className="optional">Establecimiento</th>
            </tr>
          </thead>
          <tbody>
            {estudios.length === 0 && (
              <tr>
                <td colSpan={4} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {estudios.map((item, index) => (
              <tr key={index} onClick={() => setEstudio(item)}>
                <td>{item.titulo}</td>
                <td className="optional">{item.desde}</td>
                <td className="optional">{item.hasta}</td>
                <td className="optional">{item.estado}</td>
                <td className="optional">{item.establecimiento}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={estudio}
          onCancel={() => setEstudio(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Estudio current={estudio} />
        </Modal>
      </div>

      <div className="card-form">
        <div className="header">
          <img className="icon" src={iconAsignaciones} alt="Logo" />
          <div className="data">
            <div className="title">Asignaciones por Título</div>
            <div className="description">
              Lista de asignaciones correspondientes al empleado.
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Detalle</th>
              <th className="optional">Título (%)</th>
              <th className="optional">Adicional (%)</th>
            </tr>
          </thead>
          <tbody>
            {asignaciones.length === 0 && (
              <tr>
                <td colSpan={3} className="empty">
                  No se encontro ningún resultado
                </td>
              </tr>
            )}
            {asignaciones.map((item, index) => (
              <tr key={index} onClick={() => setAsignacion(item)}>
                <td>{item.asignacionPorTitulo}</td>
                <td className="optional">{item.titulo}</td>
                <td className="optional">{item.adicional}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={asignacion}
          onCancel={() => setAsignacion(undefined)}
          footer={null}
          destroyOnClose={true}
          width={450}
        >
          <Asignacion current={asignacion} />
        </Modal>
      </div>

      <div className="page-actions">
        <Link to="/">
          <Button type="secondary">Volver</Button>
        </Link>
      </div>
    </div>
  );
};

export default Estudios;
