/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import icon from "./../../assets/img/asistencias.svg";

const Asistencia = (props) => {
  const [form] = Form.useForm();

  const { current } = props;

  useEffect(() => {
    form.setFieldsValue(current);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <img className="modal-icon" src={icon} alt="Logo" />
      <Row gutter={20}>
        <Col xs={24}>
          <Form.Item label="Fecha" name="fecha">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Hora" name="hora">
            <Input readOnly />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Reloj" name="reloj">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Asistencia;
