import React from "react";
import { Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Col, Row } from "antd";

import { bounceTransition, mapStyles } from "./../../services/router";

import Credentials from "./Credentials";
import Start from "./Start";

import "./Register.scss";

import imgLogo from "./../../assets/img/icon.png";
import imgRegister from "./../../assets/img/register.svg";

const Register = () => {
  return (
    <div className="page-register">
      <img src={imgLogo} alt="logo" className="logo" />
      <Row>
        <Col xs={0} lg={12} xl={14}>
          <div className="image">
            <img src={imgRegister} alt="login" />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={10}>
          <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"
          >
            <Route path="/register/credentials" component={Credentials} />
            <Route path="/register" exact component={Start} />
          </AnimatedSwitch>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
