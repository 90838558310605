/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import http from "./../services/http";

import placeholder from "./../assets/img/profile.png";

import "./Header.scoped.scss";

const Header = () => {
  const [foto, setFoto] = useState(placeholder);
  const [nombre, setNombre] = useState("");
  const [funcion, setFuncion] = useState("");

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`micrositios/empleados/header`);
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setFoto(data.foto ? data.foto : foto);
        setNombre(data.nombre);
        setFuncion(data.funcion);
      }
    }

    getData();
  }, []);

  return (
    <div className="header">
      <div className="photo" style={{ backgroundImage: `url(${foto})` }}></div>
      <div className="title">{nombre}</div>
      <div className="description">{funcion}</div>
    </div>
  );
};

export default Header;
